import { graphql, useStaticQuery } from "gatsby"

export default function useHowItWorksData() {
  const data = useStaticQuery(graphql`
    query getHowItWorksData {
      markdownRemark(frontmatter: {url: {eq: "howlwworks"}}) {
    id
    frontmatter {
      title
      subtitle
      tab {
        tab_title
        tab_content
      }
    }
  }
  }
  `)
  return data.markdownRemark
}
