import React from "react"
import Layout from "../../components/Layout"
import TabModule from "../../components/TabModule"
import useHowItWorksData from "../../static_queries/useHowItWorksData"

export default function HowItWorks() {
  const { frontmatter } = useHowItWorksData()
  return (
    <Layout title={frontmatter.title} subtitle={frontmatter.subtitle}>
      <TabModule tabs={frontmatter.tab}/>
    </Layout>
  )
}
