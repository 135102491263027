import React, {useEffect} from "react";
// import {InputNumber} from 'antd';

export const initTabs = () => {
  const tabLinksAll = document.getElementsByClassName('tab-link')
  if (tabLinksAll.length) {
    for (let i = 0; i < tabLinksAll.length; i++) {
      tabLinksAll[i].addEventListener('click', function (e) {
        e.preventDefault()
        let tabLinksContainer = tabLinksAll[i].parentNode.parentNode
        let tabPanels = tabLinksContainer.nextElementSibling.getElementsByClassName('tab-panel')
        let tabLinks = tabLinksContainer.getElementsByClassName('tab-link')
        // Remove is-active class from all links and panels
        for (let i = 0; i < tabLinks.length; i++) {
          tabLinks[i].classList.remove('is-active')
        }
        for (let i = 0; i < tabPanels.length; i++) {
          tabPanels[i].classList.remove('is-active')
        }
        // Get the ID of panel to display
        let tabID = this.getAttribute('href')
        // Add is-active class to matching link and panel
        tabLinksAll[i].classList.add('is-active')
        document.querySelector(tabID).classList.add('is-active')
        document.querySelector(".tabs-content").scrollTop = 0
      })
    }
  }
}

const TabModule = ({tabs}) => {
  useEffect(() => {
    initTabs()
  }, [])
  return <section className="features-tabs section">
      <div className="features-tabs-inner">
        <div className="tabs-container">
          <ul className="tabs-links">
            {
              tabs.map((tab, idx) => {
                return <li className>
                  <a href={`#tab-${idx}`} className={`tab-link ${idx === 0 && "is-active"}`}>
                    {tab.tab_image ? <img src={tab.tab_image} style={{width: 110}} alt="Tabs icon 01" /> : <img src="/uploads/lw_sprite.jpg" alt="Tabs icon 01" />}
                    <span className="h4 m-0">{tab.tab_title}</span>
                  </a>
                </li>
              })
            }
          </ul>
          <div className="tabs-content">
            {
              tabs.map((tab, idx) => {
                return <div id={`tab-${idx}`} className={`tab-panel ${idx === 0 && "is-active"}`}>
                  <h4 className="text-lg">{tab.tab_title}</h4>
                  <div dangerouslySetInnerHTML={{__html: tab.tab_content}}/>
                </div>
              })
            }
          </div>
        </div>
      </div>
  </section>
}

export default TabModule;
